import { lazy, Suspense } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from 'src/auth/guard';
import { SplashScreen } from 'src/components/loading-screen';
import systemConfig from 'src/config/system-config';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import ForgotPasswordPage from 'src/pages/auth/forgot-password-page';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/auth/login-page'));
const RegisterPage = lazy(() => import('src/pages/auth/register-page'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={systemConfig.recaptchaKey} language="en">
            <AuthModernCompactLayout>
              <LoginPage />
            </AuthModernCompactLayout>
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: 'register',
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={systemConfig.recaptchaKey} language="en">
            <AuthModernCompactLayout>
              <RegisterPage />
            </AuthModernCompactLayout>
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={systemConfig.recaptchaKey} language="en">
            <AuthModernCompactLayout>
              <ForgotPasswordPage />
            </AuthModernCompactLayout>
          </GoogleReCaptchaProvider>
        ),
      },
    ],
  },
];
