import CryptoJS from 'crypto-js';
import systemConfig from 'src/config/system-config';

// ----------------------------------------------------------------------

export const isLoggedIn = () => localStorage.getItem(systemConfig.accessTokenKey) != null;

export const setAccessToken = (value) => {
  localStorage.setItem(systemConfig.accessTokenKey, Encrypt(value, systemConfig.accessTokenKey));
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(systemConfig.accessTokenKey);
  return accessToken ? Decrypt(accessToken, systemConfig.accessTokenKey) : null;
};

// ----------------------------------------------------------------------

export const Encrypt = (message, privateKey) => CryptoJS.AES.encrypt(message, privateKey).toString();

export const Decrypt = (ciphertext, privateKey) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, privateKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
