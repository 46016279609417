import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import systemConfig from 'src/config/system-config';
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

export default function DevGuard({ children }) {
  const router = useRouter();

  const isDeveloping = localStorage.getItem(systemConfig.devKey);

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!isDeveloping) {
      router.replace('/404');
    } else {
      setChecked(true);
    }
  }, [isDeveloping, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

DevGuard.propTypes = {
  children: PropTypes.node,
};
