import { Icon } from '@iconify/react';
import { IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Label from '../label';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function Copy({ children, text = '', customButton, sx = {}, ...other }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleOnCopy = () => {
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  return (
    <>
      {children && (
        <Stack direction="row" alignItems="center" spacing={1} {...other} sx={sx}>
          {children}

          <CopyToClipboard disabled={isCopied} text={text} onCopy={handleOnCopy}>
            {customButton || (
              <IconButton color="primary" sx={{ p: 0.5, marginLeft: '2px' }}>
                <Icon
                  icon={!isCopied ? 'ep:copy-document' : 'tabler:copy-check'}
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            )}
          </CopyToClipboard>
        </Stack>
      )}
    </>
  );
}
